import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import { Merchant } from '@src/data/types/Merchant'
import { formatNumber } from '@src/services/Formatter'
import { useTranslation } from 'react-i18next'

interface MerchantRatesPanelProps {
  merchant: Merchant
}

const MerchantRatesPanel: React.FC<MerchantRatesPanelProps> = ({ merchant }) => {
  const { t } = useTranslation()

  const adminFeeCustomer = merchant.merchantBaseRates?.lenderFeeRate ?? 0
  const adminFeeMerchant = merchant.merchantBaseRates?.adminFeeMerchant ?? 0
  const customerRateModifier = merchant.merchantBaseRates?.interestRateModifier ?? 0

  return (
    <Box>
      <Typography variant="h6" gutterBottom paddingBottom={2}>
        {t('merchant.ratesAndFees.title')}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <TextField
            label={t(`merchant.ratesAndFees.adminFeeCustomer`)}
            value={formatNumber(adminFeeCustomer)}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label={t(`merchant.ratesAndFees.adminFeeMerchant`)}
            value={formatNumber(adminFeeMerchant)}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label={t(`merchant.ratesAndFees.customerRateModifier`)}
            value={formatNumber(customerRateModifier)}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default MerchantRatesPanel
