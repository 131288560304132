import axios, { AxiosError } from 'axios'
import { getUserToken } from '../providersUtils/UserProviderUtils'

const jwtToken = getUserToken()
let instance = axios.create({
  baseURL: import.meta.env.VITE_MERCHANT_API_URL,
  headers: { Authorization: `Bearer ${jwtToken}` },
})

export function initApiClient(reportApiError: (error: AxiosError) => void) {
  const currentJwtToken = getUserToken()
  instance = axios.create({
    baseURL: import.meta.env.VITE_MERCHANT_API_URL,
    headers: { Authorization: `Bearer ${currentJwtToken}` },
  })

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    (error: AxiosError) => {
      if (reportApiError) reportApiError(error)
      throw error
    },
  )
}

export function getApiClient() {
  return instance
}
