import { ApplicationInsights } from '@microsoft/applicationinsights-web'

let ai: ApplicationInsights | null = null

export default function initializeAppInsights(): ApplicationInsights {
  ai = new ApplicationInsights({
    config: {
      instrumentationKey: import.meta.env.VITE_APP_INSIGHT_INSTRUMENTAL_KEY,
    },
  })

  ai.loadAppInsights()
  return ai
}
